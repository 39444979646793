import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Amazon from "../components/images/Amazon.png";
import sFachl from "../components/images/sFachl.png";
import Opocensky from "../components/images/Opocensky.png";

const Contact = () => {
  return (
    <div>
      <div className="contact_header_section">
        <Container>
          <Row className="align-items-center">
            <Col md="5">
              <div className="contact_header_section_info">
                <h2 className="contact_header_section_h1 mb-4 pb-3">
                  Sag es durch
                  <br />
                  die Blume.
                </h2>
                <p className="contact_header_section_p mb-4 pb-3">
                  Hast du Fragen oder möchtest mit uns in Kontakt treten? Dann
                  schreib uns doch einfach!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="contact_form_section">
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <h2 className="contact_form_section_h1 mb-5 pb-3">
                Wir freuen uns
                <br />
                auf deine Nachricht!
              </h2>
              <Row>
                <Col md={6}>
                  <input type="text" className="form-control form_input" />
                  <label className="form_label">Vorname</label>
                </Col>
                <Col md={6}>
                  <input type="text" className="form-control form_input" />
                  <label className="form_label">Nachname</label>
                </Col>
                <Col md={12}>
                  <input type="email" className="form-control form_input" />
                  <label className="form_label">E-Mail Adresse</label>
                </Col>
                <Col md={12}>
                  <textarea className="form-control form_input"></textarea>
                  <label className="form_label">Deine Nachricht</label>
                </Col>
                <Col md={12}>
                  <input
                    type="submit"
                    value="Absenden"
                    className="btn theme-btn"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="contact_available_section">
        <Container>
          <Row className="mb-5">
            <Col>
              <h5>Ausserdem erhältlich bei</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <img
                src={Amazon}
                alt="Amazon"
                className="img-fluid available_img amazon_logo_img"
              />
              <img
                src={sFachl}
                alt="sFachl"
                className="img-fluid available_img"
              />
              <img
                src={Opocensky}
                alt="Opocensky"
                className="img-fluid available_img"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Contact;
