import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { CREATE_USER } from "../../constants/constant";

const initialState = {
  userCreateData: [],
};
export const createUser = createAsyncThunk(
  "admin/createUser",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${CREATE_USER}`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: "Bearer <TOKEN>",
        },
      });
      console.log("response", response);
      return response;
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

const authSlice = createSlice({
  name: "user/auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userCreateData = action.payload;
      })
      .addCase(createUser.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default authSlice.reducer;
