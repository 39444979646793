import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ADDRESS_API, ADDRESS_API_KEY } from "../../constants/constant";
import { toast } from "react-toastify";

const initialState = {
  addressSuggetion: [],
  addressvalidation: [],
};

export const getAddressSuggetion = createAsyncThunk(
  "address/suggester",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${ADDRESS_API}search?Query=${payload.address}&APIKey=${ADDRESS_API_KEY}`
      );
      return response?.data?.results;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const addressValidate = createAsyncThunk(
  "address/validator",
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${ADDRESS_API}verify?StreetAddress=${payload?.streetAddress}&CountryCode=${payload?.country}&City=${payload.city}&State=${payload?.state}&PostalCode=${payload?.postalCode}&Geocoding=true&APIKey=${ADDRESS_API_KEY}`
      );
      if (response?.data?.status === "INVALID") {
        toast.error("please enter valid address");
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const getAddressSuggetionSlice = createSlice({
  name: "address/suggester",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAddressSuggetion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAddressSuggetion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addressSuggetion = action.payload;
      })
      .addCase(getAddressSuggetion.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addressValidate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addressValidate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addressvalidation = action.payload;
      })
      .addCase(addressValidate.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default getAddressSuggetionSlice.reducer;
