import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const LostPassword = () => {
	return (
		<div>
			<div className='login_section'>
				<Container>
					<Row>
						<Col>
							<h2 className='page_title'>Passwort vergessen</h2>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<p>Hast du dein Passwort vergessen? Bitte gib deinen Benutzernamen oder E-Mail-Adresse ein. Du erhältst einen Link per E-Mail, womit du dir ein neues Passwort erstellen kannst.</p>
							<Row>
								<Col md={12}>
									<input type='email' className='form-control form_input' />
									<label className='form_label login_formlabel'>Benutzername oder E-Mail</label>
								</Col>
								<Col md={12}>
									<input type='submit' value='Passwort zurücksetzen' className='btn theme-btn' />
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default LostPassword;
