import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Field, Form, Formik } from "formik";

// import { createUser } from "../redux/slice/authSlice";
// import { OidcProvider } from "oidc-react";
// import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { UserManager } from "oidc-client";

const Login = () => {
  const userManager = new UserManager({
    authority: "https://oidc.fullcron.tech",
    client_id: "202990901239309582@webshop_at",
    redirect_uri: "https://wv2.mypoppies.com/profile",
    response_type: "code",
    scope: "openid profile email",
    userinfo_endpoint: "https://oidc.fullcron.tech/oidc/v1/userinfo",
  });
  const [authenticated, setAuthenticated] = useState(null);
  const registerUser = async () => {
    userManager.signinRedirect({ state: "a2123a67ff11413fa19217a9ea0fbad5" });
    const user = userManager.getUser();
    if (user) {
      setAuthenticated(true);
    }
    if (authenticated === null) {
      userManager
        .signinRedirectCallback()
        .then((user) => {
          if (user) {
            setAuthenticated(true);
            const access_token = user.access_token;
            fetch(userManager.userinfo_endpoint, {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            })
              .then((response) => response.json())
              .then((userInfo) => {
                console.log(userInfo);
              });
          } else {
            setAuthenticated(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setAuthenticated(false);
        });
    }
  };
  return (
    <div>
      <div className="login_section">
        <Container>
          <Row>
            <Col>
              <h2 className="page_title">Mein Konto</h2>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <h5>Anmelden</h5>
              <Row>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  onSubmit={(values) => registerUser(values)}
                >
                  <Form>
                    <Col md={12}>
                      <Field
                        type="email"
                        className="form-control form_input"
                        name="email"
                      />
                      <label className="form_label login_formlabel">
                        *E-Mail Adresse
                      </label>
                    </Col>
                    <Col md={12}>
                      <Field
                        type="password"
                        name="password"
                        className="form-control form_input"
                      />
                      <label className="form_label login_formlabel">
                        *Passwort
                      </label>
                    </Col>
                    <Col md={12}>
                      <button
                        // type="submit"
                        // value="ANMELDEN"
                        className="btn theme-btn"
                        onClick={() => registerUser()}
                      >
                        ANMELDEN
                      </button>
                    </Col>
                  </Form>
                </Formik>
              </Row>

              <Row className="mt-5">
                <Col md={12}>
                  <Link to={`/lost-password`} className="link_text">
                    Passwort vergessen?
                  </Link>
                  <p>Die mit * gekennzeichneten Wörter sind Pflichtfelder.</p>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <h5>Noch kein Konto?</h5>
              <Row>
                <Col md={12}>
                  <input type="email" className="form-control form_input" />
                  <label className="form_label login_formlabel">
                    *E-Mail Adresse
                  </label>
                </Col>
                <Col md={12}>
                  <input type="password" className="form-control form_input" />
                  <label className="form_label login_formlabel">
                    *Passwort
                  </label>
                </Col>
                <Col md={12}>
                  <p>
                    Ihre personenbezogenen Daten werden verwendet, um Ihre
                    Erfahrung auf dieser Website zu unterstützen, den Zugriff
                    auf Ihr Konto zu verwalten und für andere Zwecke, die in
                    unserer Datenschutzerklärung beschrieben sind.
                  </p>
                </Col>
                <Col md={12}>
                  <Link
                    to="https://oidc.fullcron.tech"
                    className="btn theme-btn"
                  >
                    Registrieren
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Login;
