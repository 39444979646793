export const product = [
    {
        productid: '01',
        productname: 'Mohnkapseln Mit Stiel (Natur)',
        productdescription: 'Diese Kapseln stammen aus nachhaltigem Anbau und wurden sorgfältig von Hand geerntet und gelesen. Nur die schönsten Kapseln werden manuell von Mohn befreit. Je nach Bestellmenge werden diese zu einem Strauß gebunden.',
        producttype: '',
        variations: [
            { volume: '3 Stück', price: '8,70 €', stock: '10' },
            { volume: '6 Stück', price: '17,40 €', stock: '0' }
        ],
        productcategory: ['alle', 'mohnkapseln'],
        productimage: require('../components/images/Product01.jpg')
    },
    {
        productid: '02',
        productname: 'Mohnkapseln Mit Stiel (Hell)',
        productdescription: 'Diese Kapseln stammen aus nachhaltigem Anbau und wurden sorgfältig von Hand geerntet und gelesen. Nur die schönsten Kapseln werden manuell von Mohn befreit. Je nach Bestellmenge werden diese zu einem Strauß gebunden.',
        producttype: '',
        variations: [
            { volume: '3 Stück', price: '8,70 €', stock: '10' },
            { volume: '6 Stück', price: '17,40 €', stock: '10' }
        ],
        productcategory: ['alle', 'mohnkapseln'],
        productimage: require('../components/images/Product02.jpg')
    },
    {
        productid: '03',
        productname: 'Mohnkapseln Klein',
        productdescription: 'Diese Kapseln stammen aus nachhaltigem Anbau und wurden sorgfältig von Hand geerntet. Nach Entleerung der Mohnsamen kommen nur die besten Kapseln in unsere Verpackungen.',
        producttype: '',
        variations: [
            { volume: '40 g', price: '8,00 €', stock: '10' },
            { volume: '100 g', price: '16,00 €', stock: '10' },
            { volume: '200 g', price: '30,00 €', stock: '10' }
        ],
        productcategory: ['alle', 'mohnkapseln'],
        productimage: require('../components/images/Product03.jpg')
    },
    {
        productid: '04',
        productname: 'Mohnkapseln Gross',
        productdescription: 'Diese Kapseln stammen aus nachhaltigem Anbau und wurden sorgfältig von Hand geerntet. Nach dem Entleeren von Mohnsamen kommen nur die besten Kapseln in unsere Packungen.',
        producttype: '',
        variations: [
            { volume: '100 g', price: '16,00 €', stock: '10' },
            { volume: '200 g', price: '30,00 €', stock: '10' }
        ],
        productcategory: ['alle', 'mohnkapseln'],
        productimage: require('../components/images/Product04.jpg')
    },
    {
        productid: '05',
        productname: 'Mohnkapseln Lackiert Lilac',
        productdescription: 'Die 40-50 cm langen Mohnkapseln werden sorgfältig von Hand verarbeitet und einzeln gefärbt. Die Lacke enthalten keine gesundheits- und umweltgefährdenden Lösungsmittel und sind daher besonderes schonend. Die Mohnkapseln in unterschiedlichen Farben sind ein Naturprodukt und können in Form und Größe variieren. Durch die händische Färbung wird jedes Stück noch einzigartiger.',
        producttype: '',
        variations: [
            { volume: '3 Stück', price: '8,70 €', stock: '10' },
            { volume: '6 Stück', price: '17,40 €', stock: '10' }
        ],
        productcategory: ['alle', 'mohnkapseln'],
        productimage: require('../components/images/Product05.jpg')
    },
    {
        productid: '06',
        productname: 'Mohnkapseln Lackiert Corn',
        productdescription: 'Die 40-50 cm langen Mohnkapseln werden sorgfältig von Hand verarbeitet und einzeln gefärbt. Die Lacke enthalten keine gesundheits- und umweltgefährdenden Lösungsmittel und sind daher besonderes schonend. Die Mohnkapseln in unterschiedlichen Farben sind ein Naturprodukt und können in Form und Größe variieren. Durch die händische Färbung wird jedes Stück noch einzigartiger.',
        producttype: '',
        variations: [
            { volume: '3 Stück', price: '8,70 €', stock: '10' },
            { volume: '6 Stück', price: '17,40 €', stock: '10' }
        ],
        productcategory: ['alle', 'mohnkapseln'],
        productimage: require('../components/images/Product06.jpg')
    },
    {
        productid: '07',
        productname: 'Mohnkapseln Lackiert Rubin',
        productdescription: 'Die 40-50 cm langen Mohnkapseln werden sorgfältig von Hand verarbeitet und einzeln gefärbt. Die Lacke enthalten keine gesundheits- und umweltgefährdenden Lösungsmittel und sind daher besonderes schonend. Die Mohnkapseln in unterschiedlichen Farben sind ein Naturprodukt und können in Form und Größe variieren. Durch die händische Färbung wird jedes Stück noch einzigartiger.',
        producttype: '',
        variations: [
            { volume: '3 Stück', price: '8,70 €', stock: '10' },
            { volume: '6 Stück', price: '17,40 €', stock: '10' }
        ],
        productcategory: ['alle', 'mohnkapseln'],
        productimage: require('../components/images/Product07.jpg')
    },
    {
        productid: '08',
        productname: 'Mohnkapseln Lackiert Apricot',
        productdescription: 'Die 40-50 cm langen Mohnkapseln werden sorgfältig von Hand verarbeitet und einzeln gefärbt. Die Lacke enthalten keine gesundheits- und umweltgefährdenden Lösungsmittel und sind daher besonderes schonend. Die Mohnkapseln in unterschiedlichen Farben sind ein Naturprodukt und können in Form und Größe variieren. Durch die händische Färbung wird jedes Stück noch einzigartiger.',
        producttype: '',
        variations: [
            { volume: '3 Stück', price: '8,70 €', stock: '10' },
            { volume: '6 Stück', price: '17,40 €', stock: '10' }
        ],
        productcategory: ['alle', 'mohnkapseln'],
        productimage: require('../components/images/Product08.jpg')
    },
    {
        productid: '09',
        productname: 'Blaumohn',
        productdescription: 'Verfeinere auch du deine Strudel, Nudel und Knödel durch das milde und feine Mohnaroma – My Poppies Blaumohn. Die frisch gemahlenen Mohnsamen sind verzehrfertig für dich aufbereitet und in 220 g Packungen erhältlich. Intensiv im Geschmack!',
        producttype: '',
        variations: [
            { volume: '220 g', price: '5,90 €', stock: '10' }
        ],
        productcategory: ['alle', 'superfood'],
        productimage: require('../components/images/Product09.jpg')
    },
    {
        productid: '10',
        productname: 'Graumohn',
        productdescription: 'Verfeinere auch du deine Strudel, Nudel und Knödel durch das milde und feine Mohnaroma – My Poppies Graumohn. Die frisch gemahlenen Mohnsamen sind verzehrfertig für dich aufbereitet und in 220 g Packungen erhältlich. Klassisch im Geschmack!',
        producttype: '',
        variations: [
            { volume: '220 g', price: '5,90 €', stock: '10' }
        ],
        productcategory: ['alle', 'superfood'],
        productimage: require('../components/images/Product10.jpg')
    },
    {
        productid: '11',
        productname: 'Weissmohn',
        productdescription: 'Verfeinere auch du deine Strudel, Nudel und Knödel durch das milde und feine Mohnaroma – My Poppies Weißmohn. Die frisch gemahlenen Mohnsamen sind verzehrfertig für dich aufbereitet und in 220 g Packungen erhältlich. Nussig im Geschmack!',
        producttype: '',
        variations: [
            { volume: '220 g', price: '6,90 €', stock: '10' }
        ],
        productcategory: ['alle', 'superfood'],
        productimage: require('../components/images/Product11.jpg')
    },
    {
        productid: '12',
        productname: 'Mohnöl',
        productdescription: 'Der erfolgreiche Versuch, den Geschmack eines Mohnfeldes in praktischen Flaschen einzufangen. Handcrafted und kaltgepresst wird der Blaumohn aus Niederösterreich zu jenem schmackhaften Mohnöl, das jedes Gericht verfeinert und jeden Gaumen erfreut. Durch die schonende Herstellung bleiben wertvolle biologische Bestandteile erhalten und intensivieren den Geschmack.',
        producttype: '',
        variations: [
            { volume: '100 ml', price: '8,90 €', stock: '10' }
        ],
        productcategory: ['alle', 'superfood'],
        productimage: require('../components/images/Product12.jpg')
    },
    {
        productid: '13',
        productname: 'Mymo Pflanzendrinks',
        productdescription: '',
        producttype: 'new',
        variations: [],
        productcategory: ['alle', 'neu'],
        productimage: require('../components/images/Product13.jpg')
    },
    {
        productid: '14',
        productname: 'Punct Spirituosen',
        productdescription: '',
        producttype: 'new',
        variations: [],
        productcategory: ['alle', 'neu'],
        productimage: require('../components/images/Product14.jpg')
    }
];