import { React, useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import AmazonPay from "../components/images/AmazonPay.png";
import PayPal from "../components/images/PayPal.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addressValidate,
  getAddressSuggetion,
} from "../redux/slice/addressSlice";

import "../assets/custom.css";
import useOnClickOutside from "../hook/useOnClickOutside";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Checkout = () => {
  const refLand = useRef();
  const refState = useRef();
  const refPostalCode = useRef();
  const dispatch = useDispatch();
  const { addressSuggetion } = useSelector((state) => state.addressSuggetion);
  const [address, setAddress] = useState("");
  const [landValue, setLandValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [dropdownShow, setDropdownShow] = useState(false);
  const [dropdownCityShow, setDropdownCityShow] = useState(false);
  const [dropdownShowState, setDropdownShowState] = useState(false);
  const [dropdownShowLand, setDropdownShowLand] = useState(false);
  const [AdresseValue, setAdresseValue] = useState("");
  useOnClickOutside(refLand, () => setDropdownShowLand(false));
  useOnClickOutside(refState, () => setDropdownShowState(false));
  useOnClickOutside(refState, () => setDropdownCityShow(false));
  useOnClickOutside(refPostalCode, () => setDropdownShow(false));
  useEffect(() => {
    let payload = {
      address: address,
    };
    dispatch(getAddressSuggetion({ payload }));
  }, [address, dispatch]);
  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      streetAddress: AdresseValue,
      country: landValue,
      city: cityValue,
      state: stateValue,
      postalCode: postalCode,
    };
    dispatch(addressValidate({ payload }));
  };
  return (
    <div>
      <div className="login_section">
        <Container>
          <Row className="mb-3">
            <Col md={8} className="m_order_2" >
              <form onSubmit={(e) => handleSubmit(e)}>
                <Row className="mb-5">
                  <Col md={12}>
                    <h5>Express Zahlung:</h5>
                  </Col>
                  <Col md={12}>
                    <hr className="checkout_hr" />
                  </Col>
                  <Col md={12} className="my-3">
                    <img
                      src={AmazonPay}
                      alt="AmazonPay"
                      className="checkout_img"
                    />
                    <img src={PayPal} alt="PayPal" className="checkout_img" />
                  </Col>
                  <Col md={12}>
                    <hr className="checkout_hr" />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-4">
                    <h5>Rechnungsdetails</h5>
                  </Col>
                  <Col md={6} xs={6}>
                    <input type="text" className="form-control form_input" />
                    <label className="checkout_form_label">*Vorname</label>
                  </Col>
                  <Col md={6} xs={6}>
                    <input type="text" className="form-control form_input" />
                    <label className="checkout_form_label">*Nachname</label>
                  </Col>
                  <Col md={6} xs={6}>
                    <input type="text" className="form-control form_input" />
                    <label className="checkout_form_label">Firmenname</label>
                  </Col>
                  <Col md={6} xs={6}>
                    <input type="text" className="form-control form_input" />
                    <label className="checkout_form_label">UID-Nummer</label>
                  </Col>
                  <Col md={6} xs={6}>
                    <div>
                      <input
                        type="text"
                        className="form-control form_input"
                        onChange={(e) => {
                          setAdresseValue(e.target.value);
                        }}
                        value={AdresseValue}
                      />

                      <label className="checkout_form_label">*Adresse</label>
                    </div>
                  </Col>
                  <Col md={6} xs={6}>
                    <div>
                      <input
                        type="text"
                        className="form-control form_input"
                        onChange={(e) => {
                          setCityValue(e.target.value);
                          setAddress(e.target.value);
                          setDropdownCityShow(true);
                        }}
                        value={cityValue}
                      />
                      <div
                        ref={refLand}
                        className={dropdownCityShow ? "dropdown" : "d-none"}
                      >
                        {addressSuggetion?.map((itm) => {
                          return (
                            <div
                              key={itm?.id}
                              onClick={() => {
                                setCityValue(itm?.description);
                                setDropdownShowLand(false);
                              }}
                              className="dropdown-row"
                            >
                              {itm?.description}
                            </div>
                          );
                        })}
                      </div>
                      <label className="checkout_form_label">*City</label>
                    </div>
                  </Col>
                  <Col md={6} xs={6}>
                    <div>
                      <input
                        type="text"
                        className="form-control form_input"
                        onChange={(e) => {
                          setLandValue(e.target.value);
                          setAddress(e.target.value);
                          setDropdownShowLand(true);
                        }}
                        value={landValue}
                      />
                      <div
                        ref={refLand}
                        className={dropdownShowLand ? "dropdown" : "d-none"}
                      >
                        {addressSuggetion?.map((itm) => {
                          return (
                            <div
                              key={itm?.id}
                              onClick={() => {
                                setLandValue(itm?.description);
                                setDropdownShowLand(false);
                              }}
                              className="dropdown-row"
                            >
                              {itm?.description}
                            </div>
                          );
                        })}
                      </div>

                      <label className="checkout_form_label">*Land</label>
                    </div>
                  </Col>
                  <Col md={6} xs={6}>
                    <input
                      type="text"
                      className="form-control form_input"
                      onChange={(e) => {
                        setStateValue(e.target.value);
                        setAddress(e.target.value);
                        setDropdownShowState(true);
                      }}
                      value={stateValue}
                    />
                    <div
                      ref={refState}
                      className={dropdownShowState ? "dropdown" : "d-none"}
                    >
                      {addressSuggetion?.map((itm) => {
                        return (
                          <div
                            key={itm?.id}
                            onClick={() => {
                              setStateValue(itm?.description);
                              setDropdownShowState(false);
                            }}
                            className="dropdown-row"
                          >
                            {itm?.description}
                          </div>
                        );
                      })}
                    </div>
                    <label className="checkout_form_label">Bundesland</label>
                  </Col>
                  <Col md={6} xs={6}>
                    <input
                      type="text"
                      className="form-control form_input"
                      onChange={(e) => {
                        setPostalCode(e.target.value);
                        setAddress(e.target.value);
                        setDropdownShow(true);
                      }}
                      value={postalCode}
                    />
                    <div
                      ref={refPostalCode}
                      className={dropdownShow ? "dropdown" : "d-none"}
                    >
                      {addressSuggetion?.map((itm) => {
                        return (
                          <div
                            key={itm?.id}
                            onClick={() => {
                              setPostalCode(itm?.description);
                              setDropdownShow(false);
                            }}
                            className="dropdown-row"
                          >
                            {itm?.description}
                          </div>
                        );
                      })}
                    </div>
                    <label className="checkout_form_label">*Postleitzahl</label>
                  </Col>
                  <Col md={6} xs={6}>
                    <input type="text" className="form-control form_input" />
                    <label className="checkout_form_label">*Ort</label>
                  </Col>
                  <Col md={6} xs={6}>
                    <input type="text" className="form-control form_input" />
                    <label className="checkout_form_label">
                      *Telefonnummer
                    </label>
                  </Col>
                  <Col md={6} xs={6}>
                    <input type="text" className="form-control form_input" />
                    <label className="checkout_form_label">
                      *E-Mail Adresse
                    </label>
                  </Col>
                  <Col md={12} className='checkout_bottom_text'>
                    <p>Die mit * gekennzeichneten Wörter sind Pflichtfelder.</p>
                    <label className="shipping_label">
                      <input type="checkbox" name="account_option" />
                      Rechnungadresse entspricht nicht Lieferadresse
                    </label>
                  </Col>
                </Row>
                <Row className="mt-5 pt-4">
                  <Col md={12}>
                    <h5 className="mb-0">Bezahlung</h5>
                  </Col>
                  <Col md={12}>
                    <hr className="checkout_hr" />
                  </Col>
                  <Col md={12}>
                    <label className="shipping_label">
                      <input type="radio" name="payment_option" />
                      Überweisung
                    </label>
                    <label className="shipping_label">
                      <input type="radio" name="payment_option" />
                      Sofortüberweisung
                    </label>
                    <label className="shipping_label">
                      <input type="radio" name="payment_option" />
                      Kreditkarte
                    </label>
                    <label className="shipping_label">
                      <input type="radio" name="payment_option" />
                      PayPal
                    </label>
                    <label className="shipping_label">
                      <input type="radio" name="payment_option" />
                      Amazon Pay
                    </label>
                  </Col>
                  <Col md={12}>
                    <hr className="checkout_hr" />
                  </Col>
                  <Col md={12}>
                    <label className="shipping_label condition_label">
                      <input type="checkbox" name="condition_option" />
                      <span>*Ich habe die Allgemeinen Geschäftsbedingungen gelesen und stimme ihnen zu</span>
                    </label>
                    <label className="shipping_label condition_label">
                      <input type="checkbox" name="condition_option" />
                      <span>*Ich verpflichte mich, mit dem Erwerb der Mohnkapseln diese nicht missbräuchlich sondern ausschließlich für Dekorationszwecke zu verwenden.</span>
                    </label>
                    <label className="shipping_label condition_label">
                      <input type="checkbox" name="condition_option" />
                      <span>Ich möchte den kostenlosen Newsletter abonnieren.</span>
                    </label>
                  </Col>
                  <Col md={12} className="mt-5">
                    <button type="submit" className="btn theme-btn">
                      zahlungspflichtig bestellen
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
            <Col md={4} className="m_order_1">
              <Row>
                <h5 className="mb-3">Deine Bestellung</h5>
                <p className="mb-1">
                  <span className="me-3">1</span>Blaumohn 220 g
                </p>
                <p className="mb-1">
                  <span className="me-3">2</span>Große Mohnkapsel 120 g
                </p>
                <hr className="bottom_hr" />
              </Row>
              <Row>
                <h5 className="mb-3">Warenkorb Summe</h5>
                <Col md={6}>
                  <p className="mb-0">Zwischensumme</p>
                </Col>
                <Col md={6}>
                  <p className="mb-0">21,90€ (inkl. MwSt.)</p>
                </Col>
                <hr className="bottom_hr" />
              </Row>
              <Row>
                <h5 className="mb-3">Spar Abo</h5>
                <p className="mb-1">Automatische Lieferung alle 2 Wochen ab 1. April 2023</p>
                <hr className="bottom_hr" />
              </Row>
              <Row>
                <h5 className="mb-3">Versand</h5>
                <p className="mb-1">DHL Express: 15,00€ (inkl. MwSt.)</p>
                <hr className="bottom_hr" />
              </Row>
              <Row>
                <Col md={6}>
                  <p className="mb-1 fw-bold">
                    <b>Gesamtsumme</b>
                  </p>
                </Col>
                <Col md={6}>
                  <p className="mb-1 fw-bold">
                    <b>21,90€</b>
                  </p>
                </Col>
                <Col md={12}>
                  <p className="mb-1">inkl. 0,54 € USt. 10%</p>
                  <p className="mb-1">inkl. 2,67 € USt. 20%</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Checkout;
