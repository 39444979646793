import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

const Settings = () => {
    return (
        <div>
            <div className='login_section'>
                <Container>
                    <Row>
                        <Col>
                            <h2 className='page_title'>Adresse</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <div className='account_sidemenu'>
                                <Link to='/my-account'>Dashboard</Link>
                                <Link to='/my-account/settings'>Einstellungen</Link>
                                <Link to='/my-account/downloads' className='active'>Downloads</Link>
                                <Link to='/my-account/edit-address'>Adresse</Link>
                                <Link to='/my-account/payment-methods'>Zahlungsmethoden</Link>
                                <Link to='/my-account/edit-account'>Kontodetails</Link>
                                <Link to='/my-account'>abmelden</Link>
                            </div>
                        </Col>
                        <Col md={9}></Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Settings;
