export const ADDRESS_API_KEY = "av-a27cab9c18da05d0c7f57f7f10d763e5";
export const ADDRESS_API = "https://api.address-validator.net/api/";
export const CREATE_USER =
  "https://oidc.fullcron.tech/.well-known/openid-configuration/oauth/v2/authorize";
export const PRODUCTS = "https://apix.fullcron.tech/v1/shipping_rates";
// export const userManager = new UserManager({
//   authority: "<YOUR_AUTHORITY>",
//   client_id: "<YOUR_CLIENT_ID>",
//   redirect_uri: "<YOUR_REDIRECT_URI>",
//   response_type: "id_token",
//   scope: "openid profile",
// });
