import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Facebook from '../images/Icon_Facebook.svg';
import Instagram from '../images/Icon_Instagram.svg';
import TikTok from '../images/Icon_TikTok.svg';

const Navbar = () => {
  return (
    <div className='footer_section'>
      <Container>
        <Row>
          <Col md={5}>
            <h5 className='footer_title'>Kostenloser Newsletter</h5>
            <div className='footer_newsletter'>
              <input type='email' className='form-control footer_newsletter_input' />
              <label className='footer_newsletter_label'>E-Mail Adresse</label>
            </div>
          </Col>
          <Col xs={6} md={3}>
            <h5 className='footer_title'>Kontakt</h5>
            <ul className='footer_contact_ul'>
              <li className='footer_contact_li'>Karolinengasse 33/6 <br />A-1040 Wien</li>
              <li className='footer_link_li'><Link to='tel:+4313430334'>+43 1 3430 334</Link></li>
              <li className='footer_link_li'><Link to='mailto:office@mypoppies.com'>office@mypoppies.com</Link></li>
            </ul>
            <ul className='footer_social_ul'>
              <li className='footer_social_li'><a href='https://www.facebook.com/profile.php?id=100063972548417' target="_blank" rel="noreferrer"><img src={Facebook} alt='Facebook' className='img-fluid footer_social_icon' /></a></li>
              <li className='footer_social_li'><a href='https://www.instagram.com/mypoppiescom/' target="_blank" rel="noreferrer"><img src={Instagram} alt='Instagram' className='img-fluid footer_social_icon' /></a></li>
              <li className='footer_social_li'><a href='https://www.tiktok.com/' target="_blank" rel="noreferrer"><img src={TikTok} alt='TikTok' className='img-fluid footer_social_icon' /></a></li>
            </ul>
          </Col>
          <Col xs={6} md={{ span: 3, offset: 1 }}>
            <h5 className='footer_title'>Hilfe</h5>
            <ul className='footer_link_ul'>
              <li className='footer_link_li'><Link to={`/products`}>AGB  |  FAQ</Link></li>
              <li className='footer_link_li'><Link to={`/products`}>Datenschutz</Link></li>
              <li className='footer_link_li'><Link to={`/products`}>Zahlung und Versand</Link></li>
              <li className='footer_link_li'><Link to={`/products`}>Widerrufsrecht</Link></li>
              <li className='footer_link_li'><Link to={`/products`}>Impressum</Link></li>
            </ul>
          </Col>
          <Col>
            <p className='footer_copyright_text'>&copy; {new Date().getFullYear()} MY POPPIES GMBH, ALL RIGHTS RESERVED</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Navbar;