import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useParams, Link } from 'react-router-dom';
import { product } from '../data/productsData';

function ProductDetail() {
    const { productid } = useParams();
    const thisProduct = product.find((prod) => prod.productid === productid);

    const relatedTo = product.filter(
        prod1 => prod1.productcategory[1] === thisProduct.productcategory[1] && prod1.productid !== thisProduct.productid
    );


    return (
        <div>
            <div className='product_info_section'>
                <Container>
                    <Row className='align-items-end'>
                        <Col md={6}>
                            <img src={thisProduct.productimage} alt={thisProduct.productname} className='img-fluid single_product_image' />
                        </Col>
                        <Col md={{ span: 4, offset: 1 }} className='mb-5'>
                            <h2 className='single_product_name'>{thisProduct.productname}</h2>
                            <p className='single_product_description mb-5 pb-3'>{thisProduct.productdescription}</p>
                            <p className='single_product_price mb-4'>
                                {thisProduct.variations.slice(0, 1).map(variation => (
                                    <span><span className='single_product_variations_price'>{variation.price}</span> / {variation.stock === '0' ? <span className='stock soldout'>Nicht vorrätig</span> : <span className='stock instock'>Verfügbar</span>} </span>
                                ))}
                            </p>
                            <div className='d-flex align-items-center justify-content-between'>
                                <Form.Select aria-label="Default select example" className='variation_select me-3'>
                                    {thisProduct.variations.map(variation => (
                                        <option>{variation.volume}</option>
                                    ))}
                                </Form.Select>
                                <div className='d-flex align-items-center'>
                                    MENGE : <input type="text" className="form-control form_qty_input w-50 d-inline-block" value={1} />
                                </div>
                            </div>
                            <Link to='/products' className='btn theme-btn mt-5'>Jetzt Kaufen</Link>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className='related_section home_product_section'>
                <Row>
                    <Col md='12'>
                        <h2 className='mb-4'>Bestseller</h2>
                    </Col>
                </Row>
                <Row>
                    {relatedTo &&
                        relatedTo.slice(0, 3).map((product) => (
                            <Col md={4} xs={6} className='shop_product_col' key={product.productid}>
                                <Link to={`/product/${product.productid}`} className='product_link'>
                                    <div className='product_image_div'>
                                        <img src={product.productimage} alt={product.productname} className='img-fluid' />
                                        {product.producttype === 'new' &&
                                            <h6 className='product_image_new'>Neu</h6>
                                        }
                                    </div>
                                    <div className='product_info text-center mt-4'>
                                        <h6 className='product_name'>{product.productname}</h6>
                                        {product.producttype === 'new' ?
                                            <h6 className='fw-medium product_price'>Coming Soon</h6>
                                            :
                                            <h6 className='fw-medium product_price'>
                                                {product.variations.slice(0, 1).map(variation => (
                                                    <span>
                                                        {variation.price} / {variation.volume}
                                                    </span>
                                                ))}
                                            </h6>
                                        }
                                    </div>
                                    <Link to={`/product/${product.productid}`} className='product_link_btn'>JETZT KAUFEN</Link>
                                </Link>
                            </Col>
                        ))}
                </Row>
            </Container>
        </div>
    );
}

export default ProductDetail;
