import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import poppy_seed from '../components/images/poppy_seed.jpg';
import mypoppies_owner from '../components/images/mypoppies_owner.jpg';
import poppy_pods01 from '../components/images/poppy_pods01.jpg';
import poppy_pods02 from '../components/images/poppy_pods02.jpg';
import Spirits from '../components/images/Spirits.jpg';
import Foods from '../components/images/Foods.jpg';

const Produkte = () => {
	return (
		<div>
			<div className='products_page_header'>
				<Container>
					<Row className='products_page_header_row'>
						<Col md={{ span: 5, offset: 7 }}>
							<div className='products_page_header_col ms-4'>
								<h1 className='products_page_header_title mb-4 pb-3'>Entdecke<br />My Poppies</h1>
								<p className='products_page_header_p mb-4 pb-3'>Wie die Mohnpflanze entwickelt sich auch unsere Produktwelt: Von der Dekorkapsel bis zum Gin.</p>
								<Link to='/products' className='btn theme-btn theme-btn-3'>Zum Online Shop</Link>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className='poppy_info_section'>
				<Container>
					<Row className='poppy_info_row'>
						<Col md='5' className='poppy_info_text'>
							<div className='ps-4 pe-4'>
								<h2 className='mb-5 pb-5'>Entdecke Sie, Die<br />Welt des Mohns.</h2>
								<p>Lorem ipsum que molorpore, samenessima coneser Simporio. Nonet porerspero delenda quoditias moluptibus nosapictur ma quat litatur, ut facererit dit denimusdae. Nam quosam ute que volessime por ant atinciae conet dem ut as maioreic temolor delenda quoditias moluptibus.</p>
								<p>solesto beriat. Occuptat. Ti que molorpore, samenessima coneser itatium aspel et es sa voluptatur? Sapel eosam, imolupta qui omnisti orepero verios ex eos auda que vellandam, net, consed quam, ipsa qui ommodiciti officipsam dellut qui ad quias maiossi nitiur itatium aspel et es. Simporio. Nonet porerspero delenda quoditias.</p>
							</div>
						</Col>
						<Col md={{ span: 6, offset: 1 }}>
							<img src={poppy_seed} alt='poppy_seed' className='img-fluid' />
						</Col>
					</Row>
				</Container>
			</div>
			<div className='poppy_owner_section'>
				<Container>
					<Row className='justify-content-md-center px-4'>
						<Col md='10'>
							<div className='poppy_owner_image'>
								<img src={mypoppies_owner} alt='mypoppies_owner' className='img-fluid' />
								<h1 className='poppy_owner_title'>Mohn kann<br />Mehr.</h1>
							</div>
							<div className='poppy_owner_info'>
								<p>Das ist Paul. Gründer von My Poppies, leidenschaftlicher Landwirt und Visionär. <br />Sein Bestreben liegt darin, den Mohn wieder aufleben zu lassen und den Menschen den Wert der Mohnpflanze näher zu bringen - mit dem Bewusstsein, dass eine Vielzahl an außergewöhnlich guten Produkten direkt „vor unserer Nase” wächst. </p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className='poppy_pods_section'>
				<Container>
					<Row>
						<Col md='6'>
							<img src={poppy_pods01} alt='poppy_pods01' className='img-fluid' />
							<div className='poppy_pods_info'>
								<h2>Deko-Kapseln,<br />Die Allrounder.</h2>
								<p className='mt-5 mb-0'>Lorem ipsum que molorpore, samenessima coneser Simporio. Nonet porerspero delenda quoditias moluptibus nosapictur ma quat litatur, ut facererit dit denimusdae. Nam quosam ute que volessime por ant atinciae conet dem ut as maioreic temolor delenda quoditias moluptibus. solesto beriat. Occuptat.</p>
								<Link to='/products' className='btn theme-btn mt-5'>Jetzt Kaufen</Link>
							</div>
						</Col>
						<Col md='6'>
							<img src={poppy_pods02} alt='poppy_pods02' className='img-fluid' />
						</Col>
					</Row>
				</Container>
			</div>
			<div className='superfood_section'>
				<Container>
					<Row>
						<Col md='6'>
							<div className='superfood_info'>
								<h2 className='w_font mb-0'>Superfood<br />im Glas.</h2>
								<p className='mt-5 mb-0 w_font'>Es steckt Großes hinter den kleinen Kapseln. Erfahre, was wir dir in Zukunft noch bieten werden.</p>
								<Link to='/products' className='btn theme-btn mt-5'>Jetzt Kaufen</Link>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className='comingsoon_section'>
				<Container>
					<Row className='d-block d-sm-none'>
						<Col>
							<h2 className='mb-3'>Coming Soon</h2>
						</Col>
					</Row>
					<Row className='comingsoon_row_1'>
						<Col md='4'>
							<h2 className='mb-0 d-none d-xl-block'>Coming Soon</h2>
							<h5 className='mt-5'>Punct Spirits</h5>
							<p className='mt-4'>Lorem ipsum que molorpore, samenessima coneser Simporio. Nonet porerspero delenda quoditias moluptibus nosapictur ma quat litatur, ut facererit dit denimusdae. Nam quosam ute que volessime por ant atinciae conet dem ut as maioreic temolor delenda quoditias moluptibus. solesto beriat. Occuptat..</p>
							<Link to='/products' className='btn theme-btn mt-5'>Jetzt Kaufen</Link>
						</Col>
						<Col md={{ span: 6, offset: 2 }}>
							<img src={Spirits} alt='Spirits' className='img-fluid' />
						</Col>
					</Row>
					<Row className='comingsoon_row_2 align-items-end'>
						<Col md='6'>
							<img src={Foods} alt='Foods' className='img-fluid' />
						</Col>
						<Col md={{ span: 4, offset: 1 }}>
							<h5 className='mt-5'>MyMo Foods</h5>
							<p className='mt-4'>Lorem ipsum que molorpore, samenessima coneser Simporio. Nonet porerspero delenda quoditias moluptibus nosapictur ma quat litatur, ut facererit dit denimusdae. Nam quosam ute que volessime por ant atinciae conet dem ut as maioreic temolor delenda quoditias moluptibus. solesto beriat. Occuptat..</p>
							<Link to='/products' className='btn theme-btn mt-5'>Jetzt Kaufen</Link>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default Produkte;
