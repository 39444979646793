import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { PRODUCTS } from "../../constants/constant";

const initialState = {
  productData: [],
};
// export const getAllProducts = createAsyncThunk(
//   "admin/getAllProducts",
//   async ({ data }, { rejectWithValue }) => {
//     try {
//       const response = await axios.post(`${PRODUCTS}`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//         },
//       });
//       console.log("response", response);
//       return response;
//     } catch (error) {
//       return rejectWithValue(error?.response);
//     }
//   }
// );

export const getAllProducts = createAsyncThunk(
  "admin/getAllProducts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(PRODUCTS, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      console.log("responseresponse", response);
      return response;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const productSlice = createSlice({
  name: "user/Products",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productData = action.payload;
      })
      .addCase(getAllProducts.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default productSlice.reducer;
