import { combineReducers } from "redux";
import getAddressSuggetionSlice from "../slice/addressSlice";
import authSlice from "../slice/authSlice";
import productSlice from "../slice/productSlice";
const reducer = combineReducers({
  //slice
  addressSuggetion: getAddressSuggetionSlice,
  userAuth: authSlice,
  productReducer: productSlice,
});
export default reducer;
