import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

const MyAccount = () => {
    return (
        <div>
            <div className='login_section'>
                <Container>
                    <Row>
                        <Col>
                            <h2 className='page_title'>Herzlich Willkommen!</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <div className='account_sidemenu'>
                                <Link to='/my-account' className='active'>Dashboard</Link>
                                <Link to='/my-account/settings'>Einstellungen</Link>
                                <Link to='/my-account/downloads'>Downloads</Link>
                                <Link to='/my-account/edit-address'>Adresse</Link>
                                <Link to='/my-account/payment-methods'>Zahlungsmethoden</Link>
                                <Link to='/my-account/edit-account'>Kontodetails</Link>
                                <Link to='/my-account'>abmelden</Link>
                            </div>
                        </Col>
                        <Col md={9}>
                            <p>Hallo <b>alexandra.kollwig!</b><br />(Nicht alexandra.kollwig? <Link to='/my-account' className='link_text'>Abmelden</Link>)</p>
                            <p>In deiner Konto-Übersicht kannst du deine <Link to='/my-account/orders' className='link_text'>letzten Bestellungen</Link> ansehen,<br />deine <Link to='/edit-address' className='link_text'>Liefer- und Rechnungsadresse</Link> verwalten und dein <Link to='/edit-account' className='link_text'>Passwort</Link> und die <Link to='/my-account/edit-account' className='link_text'>Kontodetails</Link> bearbeiten.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default MyAccount;
